import {
  Routes,
  Route,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import './App.css';
import Calculator from "./DPCComponents/MapWithRoute";
// import AddAddress from "./Components/AddAddress";
import AddressesList from "./Components/ShowAddress";
import { CreateRoutes } from "./Components/MultiAddress/createRoutes";
import { CreateRoutes as AllocationRoute } from "./DPCComponents/RoutesAllocation/createRoutes";
import AddAddress from "./DPCComponents/AddAddress";
import RouteMap from "./Components/RouteMap";
import DPCLayout from "./DPCComponents/DPCLayout";

function Header() {
  const navigate = useNavigate();
  return (
    <header
      className="header"
      onClick={() => navigate('/')}
      style={{ cursor: 'pointer' }}
    >
      <h1>DPC</h1>
    </header>
  );
}

function App() {
  return (
    <div className='background'>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<DPCLayout />} />
          <Route path="/home" element={<Calculator />} />
          <Route path="/addAddress" element={<AddAddress />} />
          <Route path="/listAddress" element={<AddressesList />} />
          <Route path="/createRoutes" element={<CreateRoutes />} />
          <Route path="/routeMap" element={<RouteMap />} />
          <Route path="/route" element={<AllocationRoute />} />
        </Routes>
      </BrowserRouter>
      <div className="mt-1 mb-1" style={{ textAlign: 'center' }}>
        Novisync © Copyright 2024. All Rights Reserved. Release Date: 11/13/2024 2:40 AM IST
      </div>
    </div>
  );
}

export default App;
