import React, { useState, useRef, useEffect } from 'react';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { MAP_KEY } from '../../config';
import Circle from '../../images/circle.png';
import Location from '../../images/location.png';
import UpDown from '../../images/updown.png';
import Vector from '../../images/vector.png';


const libraries = ['places']; // Specify 'places' library

const PlaceAutocomplete = ({ label, setLocation, stop = false, stops , index = 0, showRoute}) => {

    const searchBoxRef = useRef(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: MAP_KEY,
        libraries,
    });

    const onLoad = (ref) => {
        searchBoxRef.current = ref;
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places.length > 0) {
            const selectedPlace = places[0];
            setLocation({
                name: selectedPlace.name,
                address: selectedPlace?.formatted_address,
                location: {
                    lat: selectedPlace?.geometry?.location.lat(),
                    lng: selectedPlace?.geometry?.location.lng(),
                },
            });
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className="mt-2 labels">
            <label style={{ marginLeft: '2rem', fontWeight: 'normal' }}>{label}</label>
            <div className='stopIcon'>
                <div style={{ display: 'grid', justifyItems: 'center' }}>
                {(stop && (stop === 'source')) ? (<img
                    src={Circle}
                    alt="Create Address"
                    className='iconStyle'
                />) : (index === stops?.length && stop !=='source') ? (<img
                    src={Location}
                    alt="Create Address"
                    className='iconStyle'
                />): (<img
                    src={Circle}
                    alt="Create Address"
                    className='iconStyle'
                />)}
                 {!(index === stops?.length && stop !=='source') &&
                 (<img
                    src={Vector}
                    alt="Up Down"
                    className='upDownIcon'
                />)}
                </div>
                <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                    <input
                        type="text"
                        className='source'
                        placeholder={`Enter ${label.toLowerCase()}`}
                        style={{ width: '100%', padding: '10px', fontSize: '16px' }}
                    />
                </StandaloneSearchBox>
                {stop !=='source' && (
                    !showRoute  && <img
                    src={UpDown}
                    alt="Up Down"
                    className='iconStyle'
                />)}
            </div>
        </div>
    );
};

const GeocodeAddress = ({ setSourceLocation, setDestinationLocation, addStopLocation, showRoute }) => {
    const [source, setSource] = useState(null);
    const [destination, setDestination] = useState(null);
    const [stops, setStops] = useState([]); // Array to hold multiple stops

    useEffect(() => {
        // Only update if source or destination changes
        if (source) {
            setSourceLocation({
                lat: source.location.lat,
                lng: source.location.lng,
            });
        }
        if (destination) {
            setDestinationLocation({
                lat: destination.location.lat,
                lng: destination.location.lng,
            });
        }
        // eslint-disable-next-line
    }, [source, destination]); // Only run effect when source or destination changes

    const handleRemoveStop = (index) => {
        const updatedStops = stops.filter((_, stopIndex) => stopIndex !== index);
        setStops(updatedStops);
        addStopLocation(updatedStops)
    };

    const addNewStopInput = () => {
        setStops([...stops, null]); // Add a placeholder for a new stop
    };

    const updateStopLocation = (index, location) => {
        const updatedStops = [...stops];
        updatedStops[index] = location;
        setStops(updatedStops);
        addStopLocation(location);
    };


    return (
        <div>
            <h5 style={{ textAlign:'center'}}>Select Destination</h5>
            <PlaceAutocomplete label="Source Address" setLocation={setSource} stop="source" showRoute={showRoute}/>
            <PlaceAutocomplete label="Destination Address" setLocation={setDestination} stop="destination" stops={stops} showRoute={showRoute}/>
            {/* 
            {source && (
                <div>
                    <h3>Source</h3>
                    <p>Address: {source.address}</p>
                </div>
            )}

            {destination && (
                <div>
                    <h3>Destination</h3>
                    <p>Address: {destination.address}</p>
                </div>
            )} */}

            {
                source && destination && (
                    <div className="mt-2">
                        {/* <h3>Stop Locations</h3> */}
                        {stops.map((stop, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>
                                <PlaceAutocomplete
                                    label={`Destination Address ${index + 1}`}
                                    setLocation={(location) => updateStopLocation(index, location)}
                                    stop={`Destination Address ${index + 1}`}
                                    stops={stops}
                                    index={index+1}
                                    showRoute={showRoute}
                                />
                                {/* {stop && (
                                    <div>
                                        <p>Address: {stop.address}</p>

                                    </div>
                                )} */}
                                {/* <button
                                    onClick={() => handleRemoveStop(index)}
                                    style={{
                                        padding: '5px',
                                        color: 'white',
                                        backgroundColor: 'red',
                                        border: 'none',
                                        borderRadius: '5px',
                                        marginTop: '5px',
                                    }}
                                >
                                    Remove Stop
                                </button> */}
                            </div>
                        ))}
                        <div
                            onClick={addNewStopInput}
                            style={{
                                padding: '10px',
                                fontSize: '1.1rem',
                                cursor: 'pointer'
                            }}
                        >
                            + Add Another Destination
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default GeocodeAddress;
